@tailwind base;
@tailwind components;
@tailwind utilities;
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}
*::-webkit-scrollbar {
  width: 8px;
}

*::-webkit-scrollbar-thumb {
  background-color: rgb(201, 201, 206);
  border-radius: 20px;
}

.notif::-webkit-scrollbar {
  width: 4px;
}
.notif::-webkit-scrollbar-thumb {
  background-color: #b7b7df;
  border-radius: 20px;
}

@layer utilities {
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}

.background {
  width: 100%;
  height: 1019px;
  /* position: relative;
    bottom: 69px; */
  z-index: -1;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  @media (min-width: 1024px) {
    width: auto;
    height: 756px;
  }
}

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

.slick-slide > div {
  margin: 0 10px;
}

.slick-next:before {
  content: "" !important;
}

.slick-prev:before {
  content: "" !important;
}

.slick-next {
  @media (min-width: 768px) {
    right: 0% !important;
    top: -18% !important;
  }

  @media (max-width: 767px) {
    top: 102% !important;
    right: 40% !important;
  }

  @media (max-width: 450px) {
    top: 102% !important;
    right: 30% !important;
  }
}
.slick-prev {
  @media (min-width: 1280px) {
    left: 92% !important;
    top: -12% !important;
  }

  @media (max-width: 1280px) {
    left: 90% !important;
    top: -12% !important;
  }

  @media (min-width: 768px) {
    left: 86% !important;
    top: -12% !important;
  }

  @media (max-width: 767px) {
    top: 108% !important;
    left: 40% !important;
  }

  @media (max-width: 560px) {
    top: 108% !important;
    left: 36% !important;
  }
}

.dropdown:hover .dropdown-menu {
  display: block;
}

.bg-pic {
  background-image: url("./images/Group 3247.svg");
  background-size: 100% 100%;
  min-width: 256px;
  height: 57px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.light .light\:bg-pic-light {
  background-image: url("./images/Group 32.svg");
  background-size: 100% 100%;
  width: 256px;
  height: 57px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.purple .purple\:bg-pic-purple {
  background-image: url("./images/Group 324.svg");
  background-size: 100% 100%;
  min-width: 256px;
  height: 57px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.logo-pic {
  background-image: url("./images/948b3ad28ed67e63c760b2ae2c01e709.png");
  background-size: 100% 100%;
}

.purple .purple\:logo-pic-purple {
  background-image: url("./images/Group 2326.svg");
  background-size: 100% 100%;
}

.ellipse-icon {
  background-image: url("./images/Ellipse 99.svg");
  background-size: 100% 100%;
}

.search-icon {
  background-image: url("./images/magnifying-glass.svg");
  background-size: 100% 100%;
}

.purple .purple\:search-icon-purple {
  background-image: url("./images/magnifying-glass-purple.svg");
  background-size: 100% 100%;
  width: fit-content;
}

.hover\:shadow-md:hover {
  box-shadow: 0 4px 6px -1px rgba(255, 106, 106, 0.1),
    0 2px 4px -1px rgba(255, 106, 106, 0.06);
}

.bg-img {
  background-image: url("./images/profilebg.png");
}

.bg-img-mobile {
  background-image: url("./images/mobileprofile.svg");
}

.slick-list,
.slick-track {
  height: 100% !important;
}

.slick-slide > div {
  height: 100%;
}

.slick-dots {
  position: absolute;
  z-index: 999;
  bottom: 0;
}

.marquee {
  /* height: 50px; */
  overflow: hidden;
  position: relative;
  /* background: #fefefe; */
  color: #333;
  border: 1px solid #4a4a4a;
  display: flex;
  align-items: center;
  white-space: nowrap;
}
.marquee {
  overflow: hidden;
  position: relative;
  white-space: nowrap;
  display: flex;
  align-items: center;
}

.marquee-content {
  display: flex;
  animation: scroll-left 80s linear infinite;
}

.marquee-item {
  flex: none;
  padding: 0 20px; /* Adjust padding for spacing between the text */
}

@keyframes scroll-left {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-50%);
  }
}

.pagination > .page-item {
  display: flex;
  align-items: center;
  justify-content: center;
}
