.list {
  border: 1px solid rgba(90, 53, 255, 0);
  box-shadow: 0px 8px 40px 0px rgba(112, 144, 176, 0);
}

.list:hover {
  border: 1px solid rgba(90, 53, 255, 0.1);
  border-radius: 5px;
  box-shadow: 0px 8px 40px 0px rgba(112, 144, 176, 0.13);
}

.my-border {
  border: 4px solid yellow;
}
